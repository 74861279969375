<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="employmentStatuses"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      employmentStatuses: [
        { title: 'Employed', value: 'employed' },
        { title: 'Self Employed', value: 'self_employed' },
      ],
    };
  },
};
</script>
